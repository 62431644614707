<template>
  <div v-loading.lock="loading" class="elv-report-contacts-container">
    <p class="elv-report-contacts-title">{{ t('project.nav.contacts') }}</p>
    <div class="elv-report-contacts-header">
      <el-tabs v-model="tabActiveName" class="elv-report-contacts-tab" @tab-click="onChangeTab">
        <el-tab-pane :label="t('project.nav.contacts')" name="reports-contacts-named" class="elv-report-detail-pane">
        </el-tab-pane>
        <el-tab-pane :label="t('report.unidentified')" name="reports-contacts-unnamed" class="elv-report-detail-pane">
        </el-tab-pane>
      </el-tabs>
      <div class="elv-report-contacts-operating">
        <div class="elv-reports-contacts-functional__edit" @click="onBatchEdit">
          <SvgIcon name="reports-batch-edit" width="16" height="16" />
          {{ t('button.edit') }}({{ reportStore.batchEditSelected.length }})
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>

  <AccountOrContactDrawer
    ref="overlayDrawerRef"
    :is-batch="true"
    drawer-title="Contact"
    type="contacts"
    :total-contacts="reportStore.batchEditSelected.length"
    :drawer-data="{}"
  />
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import AccountOrContactDrawer from '../components/AccountOrContactDrawer.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const menuGlobalStore = useMenuGlobalStore()

const loading = ref(false)
const overlayDrawerRef = ref()
const tabActiveName = ref('reports-contacts-named')

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const headerWidth = computed(() => {
  return utils.isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const onChangeTab = (tab: any) => {
  if (tab.props.name === 'reports-contacts' && !currentEntityPermission.value?.contact?.view) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  reportStore.batchEditSelected = []
  router.push({
    name: tab.props.name
  })
}

const onBatchEdit = () => {
  if (!currentEntityPermission.value?.contact?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.batchEditSelected.length) overlayDrawerRef.value?.onCheckDrawerStatus()
}

watch(
  () => route.query,
  async () => {
    try {
      if (route.name === 'reports-contacts-named' || route.name === 'reports-contacts-unnamed') {
        tabActiveName.value = String(route.name)
        loading.value = true
        await reportStore.concatDataInit(entityId.value)
        emitter.emit('resetList')
        loading.value = false
      }
    } catch (error) {
      loading.value = false
      console.log(error)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-report-contacts-header {
  position: relative;
}

.elv-report-contacts-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #000000;
  margin: 0;
  margin-left: 20px;
  margin-top: 16px;
}

:deep(.elv-report-contacts-tab) {
  .el-tabs__header {
    width: v-bind('headerWidth');
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .el-tabs__nav-wrap:after {
    height: 1px !important;
  }

  .el-tabs__nav.is-top {
    padding-left: 20px !important;
  }

  .el-tabs__active-bar {
    background-color: $elv-color-1343BF;
  }

  .el-tabs__item.is-active {
    color: $elv-color-394048;
  }

  .el-tabs__item {
    color: $elv-color-858B92;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    padding: 0;
    padding-left: 24px;
  }
}

.elv-report-contacts-operating {
  position: absolute;
  bottom: 4px;
  right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;

  .elv-reports-contacts-functional__edit {
    float: right;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: 32px;
    border: 1px solid #dde1e6;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;

    svg {
      fill: #1e2024;
      margin-right: 8px;
    }

    &:hover {
      border: 1px solid #7596eb;
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }
  }
}

:deep(.elv-financial-process-table) {
  padding-top: 0px !important;
}
</style>
